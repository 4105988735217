import React from "react";
import { Global, css } from "@emotion/react";
import Image from "~components/Image";
import styled from "@emotion/styled";

export const breakpoints = {
	mobile: "@media (max-width: 768px)",
	tablet: "@media (max-width: 1024px)",
	desktop: "@media (max-width: 1440px)",
	desktopUp: "@media (min-width: 1025px)",
};

export const mobile = breakpoints.mobile;
export const tablet = breakpoints.tablet;
export const desktop = breakpoints.desktop;
export const desktopUp = breakpoints.desktopUp;

const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
);

const styles = css`
	body {
		font-family: "Good Sans", sans-serif;
		font-size: 19px;
		line-height: 1.62;
		background-color: var(--lightBlue);
		transition: background-color 0.3s;
		${tablet} {
			font-size: 17px;
			line-height: 1.63;
		}
	}

	:root {
		--white: #ffffff;
		--black: #000000;
		--blue: #0669f9;
		--lightBlue: #def3ff;
		--green: #33908b;
		--lightGreen: #6eb06d;
		--grey: #a7a7a7;
		--red: #d82a1d;
		--yellow: #ffd700;
		--s: 16px;
		--m: 24px;
		--l: 48px;
		--xl: 80px;
	}

	${tablet} {
		:root {
			--s: 12px;
			--m: 20px;
			--l: 40px;
			--xl: 56px;
		}
	}

	p {
		margin-bottom: 0.5em;
		max-width: 680px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	h2,
	.h2 {
		font-size: 38px;
		line-height: 1.2;
		max-width: 680px;
		${tablet} {
			font-size: 28px;
		}
	}

	h3,
	.h3 {
		font-family: "Plakkaat", sans-serif;
		font-size: 50px;
		line-height: 1;
		letter-spacing: 0.02em;
		max-width: 680px;
		${tablet} {
			font-size: 31px;
		}
	}

	h4,
	.h4 {
		font-size: 26px;
		line-height: 1.2;
		max-width: 680px;
		${tablet} {
			font-size: 22px;
		}
	}

	h5,
	.h5 {
		font-family: "Plakkaat", sans-serif;
		font-size: 32px;
		line-height: 1.56;
		letter-spacing: 0.02em;
		max-width: 680px;
		${tablet} {
			font-size: 20px;
			line-height: 1.35;
		}
	}
`;
export const HeaderImageContainer = styled.div`
	position: relative;
`;
export const HeaderImage = styled(Image)`
	margin-right: var(--l);
	position: relative;
	z-index: 1;
	border-radius: 10px;
	overflow: hidden;
	${mobile} {
		margin-right: 19px;
	}
`;
export const ImageShadow = styled.div`
	background-color: ${(props) =>
		props.color ? `var(--${props.color})` : "var(--blue)"};
	margin-left: 40px;
	position: absolute;
	transform: translateY(calc(var(--l) * -1));
	border-radius: 10px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	${mobile} {
		transform: translateY(-19px);
		margin-left: 19px;
	}
`;
export const RoundedImage = styled(Image)`
	border-radius: 10px;
	overflow: hidden;
	width: 100%;
`;
export const ImageSlide = styled(RoundedImage)`
	margin-right: var(--m);
`;
export const SliderCaption = styled.h5`
	text-align: center;
`;
export const ImageCaption = styled.div`
	margin-top: var(--m);
`;
export const ImageSlider = styled.div`
	margin-bottom: var(--l);
`;
const reset = css`
	body {
		margin: 0px;
		min-height: 100%;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: inherit;
		font-size: inherit;
	}

	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
	}

	ul,
	ol,
	blockquote {
		margin: 0;
		padding: 0;
	}

	li {
		margin: 0.5em 0;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	* {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		/*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
		overflow-anchor: none;
	}

	button {
		-webkit-appearance: none;
		border: none;
		background: none;
		font-size: inherit;
		font-family: inherit;
		cursor: pointer;
		padding: 0;
		margin: 0;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: inherit;
		-webkit-box-shadow: 0 0 0px 0px transparent inset;
		transition: background-color 5000000s ease-in-out 0s;
	}

	/* Prevent safari blue links */
	button,
	option {
		color: inherit;
	}
`;

export default GlobalStyles;
